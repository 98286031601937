import Cookies from 'universal-cookie';
import axiosAdmin from '../../api/axiosAdmin';
import axiosClient from '../../api/axiosClient';
import axiosPublic from '../../api/axiosPublic';
import CustomNotification from '../../components/CustomNotification';
import { expireTime } from '../../utils';
import axiosManager from '../../api/axiosManager';

const cookies = new Cookies();

const redirectByAccess = (accessData, handleNavigate) => {
    if (accessData.LMS) {
        handleNavigate('/client');
    } else if (accessData.MI4) {
        handleNavigate('/Page1');
    } else {
        handleNavigate('/');
    }
}

const authModel = {
    state: {
        admin: {},
        client: {},
        manager: {},
    },
    reducers: {
        setUserDetails(state, payload) {
            return { ...state, [payload.name]: payload.data };
        }
    },
    effects: (dispatch) => ({
        async loginAdmin(payload) {
            const { formData, handleNavigate } = payload;
            await axiosPublic.post('/api/auth/login', formData).then(async (res) => {
                console.log(JSON.stringify(res.data), "TEsting Login Response");
                if (res.data.code === 200) {
                    localStorage.clear();
                    switch (res.data.data.role) {
                        case "Admin":
                            cookies.set(`x-auth-token`, res.data.data.token, { path: '/', expires: expireTime(), secure: true, sameSite: 'strict' });
                            await this.getAdminDetails();
                            handleNavigate('/user');
                            break;
                        case "Manager":
                            // sessionStorage.setItem('m-auth-token', res.data.data.token);
                            cookies.set(`m-auth-token`, res.data.data.token, { path: '/', expires: expireTime(), secure: true, sameSite: 'strict' });
                            await this.getManagerDetails();
                            handleNavigate('/manager/user');
                            break;
                        default:
                            cookies.set(`c-auth-token`, res.data.data.token, { path: '/', expires: expireTime(), secure: true, sameSite: 'strict' });
                            await this.getClientDetails();
                            redirectByAccess(res.data.data, handleNavigate);
                            break;
                    }
                } else {
                    CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
        async getAdminDetails() {
            console.log("getAdminDetails");
            await axiosAdmin.get(`/api/user/profile`).then(res => {
                if (res.data.code === 200) {
                    this.setUserDetails({ name: "admin", data: res.data.data });
                } else {
                    CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
        async getManagerDetails() {
            console.log("getManagerDetails");
            await axiosManager.get(`/api/user/profile`).then(res => {
                if (res.data.code === 200) {
                    console.log("manager data", res.data.data);
                    this.setUserDetails({ name: "manager", data: res.data.data });
                } else {
                    CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
        async getClientDetails() {
            await axiosClient.get(`/api/user/profile`).then(res => {
                if (res.data.code === 200) {
                    this.setUserDetails({ name: "client", data: res.data.data });
                } else {
                    CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
        async changePassword(payload) {
            const { formData, onCancel, admin } = payload;
            await (admin ? axiosAdmin : axiosClient).post(`/api/user/changepassword`, formData).then(res => {
                if (res.data.code === 200) {
                    onCancel();
                    CustomNotification({ title: "Success", subTitle: res.data.message, dispatch });
                } else {
                    CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
        async resetPassword(payload) {
            const { formData, handleNavigate } = payload;
            await axiosPublic.post(`/api/auth/forgot-password/reset`, formData).then(res => {
                if (res.data.code === 200) {
                    CustomNotification({ title: "Success", subTitle: res.data.message, dispatch });
                    handleNavigate('/');
                } else {
                    CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
        async verifyToken(payload) {
            const { setEmail, formData, handleNavigate } = payload;
            await axiosPublic.post(`/api/auth/forgot-password/verify`, formData).then(res => {
                if (res.data.code === 200) {
                    setEmail(res.data.data.email);
                } else {
                    CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                    handleNavigate('/');
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
                handleNavigate('/');
            })
        },
        async forgotPassword(payload) {
            const { formData, handleNavigate } = payload;
            await axiosPublic.post('/api/auth/forgot-password/sendLink', formData).then(res => {
                if (res.data.code === 200) {
                    CustomNotification({ title: "Success", subTitle: res.data.message, dispatch });
                    handleNavigate('/');
                } else {
                    CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
    }),
};

export default authModel;