import axios from 'axios';
import moment from 'moment';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const getAdminAccessToken = () => cookies.get('x-auth-token');
export const isAuthenticated = () => !!getAdminAccessToken();

export const getManagerAccessToken = () => cookies.get('m-auth-token');
export const isManagerAuthenticated = () => !!getManagerAccessToken();

export const getClientAccessToken = () => cookies.get('c-auth-token');
export const isClientAuthenticated = () => !!getClientAccessToken();

export const normalizeUrl = (path) => {
  if (path.split('/').length > 2) {
    return path.split('/').slice(0, -1).join('/');
  }
  return path;
};

export function downloadHandler(url, name, s3) {
  if (!s3) {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.download = url.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    axios
      .get(url, { responseType: 'blob', mode: 'no-cors' })
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTLM element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', name); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }
}

export function downloadCSVData(fileName, urlData) {
  var hiddenElement = document.createElement('a');
  hiddenElement.href = urlData;
  hiddenElement.target = '_blank';

  //provide the name for the CSV file to be downloaded
  hiddenElement.download = `${fileName}.csv`;
  hiddenElement.click();
}

export const getQueryValue = (key) => {
  const queryValues = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop)
  });
  // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
  return queryValues?.[key] || null; // "some_value"
};

export const expireTime = () => {
  return new Date(Date.now() + 8 * (60 * 60 * 1000));
};
export const dateExpireTime = () => {
  return new Date(Date.now() + 365 * 24 * (60 * 60 * 1000));
};

export function arrayOfRange(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

export function formatPhoneNumber(phoneNumberString) {
  if (phoneNumberString.length > 10) {
    // short answer but not format while typing

    return phoneNumberString
      .replace(/\D+/g, '')
      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  } else {
    // this can format value while typing

    let phone = phoneNumberString.replace(/\D/g, '');
    const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      phone = `(${match[1]}${match[2] ? ') ' : ''}${match[2]}${match[3] ? '-' : ''
        }${match[3]}`;
    }
    return phone;
  }
}

export const normalizePhone = (phone) => {
  let cleaned = phone.replace(/[^\w\s]/gi, '');
  return cleaned.replace(' ', '');
};

export const lastFifityYears = (curYear) => {
  let years = [];

  for (let i = 0; i < 50; i++) {
    years.push({ label: curYear - i, value: curYear - i });
  }

  return years;
};
