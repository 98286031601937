import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Routes, useNavigate, Route } from 'react-router-dom';
import privateRouter from './routes/PrivateRouter';
import publicRouter from './routes/PublicRouter';
import clientRouter from './routes/ClientRouter';
import store from './store';
import NotFound from './Layout/NotFound';
import { useSelector } from 'react-redux';
import managerRouter from './routes/ManagerRouter';

const cookies = new Cookies();

function App() {
  const client = useSelector(state => state.authModel.client);
  const manager = useSelector(state => state.authModel.manager);
  const { dispatch } = store;
  let navigate = useNavigate();

  const handleNavigate = (link) => {
    navigate(link);
  };

  useEffect(() => {
    const adminToken = cookies.get('x-auth-token');
    const clientToken = cookies.get('c-auth-token');
    const managerToken = cookies.get('m-auth-token');
    console.log(managerToken,"managerToken-->")
    if (managerToken) {

      dispatch.authModel.getManagerDetails();
    } else if (adminToken) {
      dispatch.authModel.getAdminDetails();
    }
    if (clientToken) {
      dispatch.authModel.getClientDetails();
    }
  }, []);

  return (
    <>
      <Routes>
        {publicRouter(handleNavigate)}
        {privateRouter(handleNavigate)}
        {clientRouter(handleNavigate, client)}
        {managerRouter(handleNavigate, manager)}
        <Route path="*" element={<NotFound handleNavigate={handleNavigate} />} />
      </Routes>
    </>
  );
}

export default App;
