import axios from "axios";
import Cookies from 'universal-cookie';
import { expireTime } from "../utils";

const cookies = new Cookies();

const axiosManager = axios.create({
    baseURL: process.env.REACT_APP_END_POINT,
});

axiosManager.defaults.timeout = 600000;

axiosManager.interceptors.response.use(
    function (response) {
        if (response.data.code === 401) {
            cookies.remove('x-auth-token', { path: '/', expires: expireTime(), secure: true, sameSite: 'strict' });
            cookies.remove('m-auth-token', { path: '/', expires: expireTime(), secure: true, sameSite: 'strict' });
            cookies.remove('c-auth-token', { path: '/', expires: expireTime(), secure: true, sameSite: 'strict' });
            window.location.reload();
        }
        return response;
    },
    function (response) {
        if (response.data.code === 401) {
            cookies.remove('x-auth-token', { path: '/', expires: expireTime(), secure: true, sameSite: 'strict' });
            cookies.remove('m-auth-token', { path: '/', expires: expireTime(), secure: true, sameSite: 'strict' });
            cookies.remove('c-auth-token', { path: '/', expires: expireTime(), secure: true, sameSite: 'strict' });
            window.location.reload();
        }
        console.error("Looks like there was a problem. Status Code:" + response?.status);
        return Promise.reject(response);
    }
);

axiosManager.interceptors.request.use(function (config) {
    const token = cookies.get('m-auth-token');
    console.log(token, "TEst --token")
    config.headers.authorization = token || '';
    return config;
});

export default axiosManager;