import moment from 'moment';
import axiosClient from '../../api/axiosClient';
import CustomNotification from '../../components/CustomNotification';
import uniqid from 'uniqid';
import DeleteUser from '../../components/ActionPop/DeleteUser';
import axiosAdmin from '../../api/axiosAdmin';
import axiosManager from '../../api/axiosManager';

const clientModel = {
  state: {
    clientList: {
      docs: [],
      page: 1,
      totalDocs: 0,
      limit: 10
    },
    allClientList: {
      docs: [],
      page: 1,
      totalDocs: 0,
      limit: 10
    },
    cleanFiles: {
      docs: [],
      page: 1,
      totalDocs: 0,
      limit: 10
    },
    ncoaFiles: {
      docs: [],
      page: 1,
      totalDocs: 0,
      limit: 10
    },
    exportData: {},
    clientDetails: {},
    jopDrops: {
      docs: [],
      page: 1,
      totalDocs: 0,
      limit: 10
    },
    initialdetails: {}
  },
  reducers: {
    setClientData(state, payload) {
      return { ...state, [payload.name]: { ...payload.data } };
    }
  },
  effects: (dispatch) => ({
    async getAllClients(payload) {

      let query = `?page=${payload.page || 1}&sort=${JSON.stringify({ "leastCompleted": payload?.sort?.leastNcoa || -1 })}&search=${payload.search ?? ''}`;

      await axiosClient
        .get(`/api/client${query}`)
        .then((res) => {
          if (res.data.code === 200) {
            this.setClientData({ name: 'clientList', data: res.data.data });
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async getAllClientsLists({ isManager, ...payload }) {
      const query = payload.search
        ? `?page=${payload.page || 1}&search=${payload.search}`
        : `?page=${payload.page || 1}`;
      await (isManager ? axiosManager : axiosAdmin)
        .get(`/api/admin/client/get${query}`, payload)
        .then((res) => {
          if (res.data.code === 200) {
            this.setClientData({ name: 'allClientList', data: res.data.data });
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async wishlistClient(payload, state) {
      await axiosClient
        .post(`/api/client/fav`, payload)
        .then((res) => {
          if (res.data.code === 200) {
            this.getAllClients({ page: state.clientModel.page });
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async verifyClientLocation(payload) {
      const { setLocDetails, data } = payload;
      await axiosClient
        .post(`/api/client/get-location`, data)
        .then((res) => {
          if (res.data.code === 200) {
            setLocDetails(res.data.data?.[0] || {});
            if (res.data.data.length === 0) {
              CustomNotification({
                title: 'Info',
                subTitle:
                  'Unable to fetch location. Please check your adrees an try again.',
                dispatch
              });
            }
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async getClientLocDetails(payload) {
      const { data, form } = payload;
      await axiosClient
        .post(`/api/client/get-location-by-lat-long`, data)
        .then((res) => {
          if (res.data.code === 200) {
            if (res.data.data.length > 0) {
              form.setFieldValue('zip', res.data.data[0].zipcode);
            }
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async addClient(payload) {
      const { data, handleNavigate } = payload;
      await axiosClient
        .post(`/api/client`, data)
        .then((res) => {
          if (res.data.code === 200) {
            this.getAllClients({ page: 1 });
            CustomNotification({
              title: 'Success',
              subTitle: res.data.message,
              dispatch
            });
            handleNavigate('/client');
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async updateClient(payload) {
      const { data, id } = payload;
      await axiosClient
        .put(`/api/client/${id}`, data)
        .then((res) => {
          if (res.data.code === 200) {
            this.getAllClients({ page: 1 });
            CustomNotification({
              title: 'Success',
              subTitle: res.data.message,
              dispatch
            });
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async deleteClient(id, state) {
      await axiosClient
        .delete(`/api/client/${id}`)
        .then((res) => {
          if (res.data.code === 200) {
            this.getAllClients({ page: state.clientModel.page });
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        })
        .finally(() => {
          dispatch.notiModel.setModalData({});
        });
    },
    async getClient(payload) {
      const { id, setClientData, form, setLocDetails, isSet = true } = payload;

      await axiosClient
        .get(`/api/client/${id}`)
        .then((res) => {
          if (res.data.code === 200) {
            if (isSet) {
              setClientData(res.data.data);

              setLocDetails({
                latitude: res.data.data.location.coordinates[1],
                longitude: res.data.data.location.coordinates[0]
              });
              form.setFieldsValue(res.data.data);
              this.setClientData({
                name: 'clientDetails',
                data: res.data.data
              });
            } else {
              this.setClientData({
                name: 'clientDetails',
                data: res.data.data
              });
            }
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async uploadFile(payload) {
      const { data, setFile, handleFileList, key } = payload;
      await axiosClient
        .post(`/api/file/upload/s3?type=${data.type}`, data.file)
        .then((res) => {
          if (res.data.code === 200) {
            if (data.type === 3) {
              handleFileList(res.data.data, key);
            } else {
              setFile(res.data.data);
            }
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async addCleanFileList(payload) {
      const { data } = payload;

      const getPayload = {
        clientId: data.clientId,
        page: 1,
        date_from: moment().startOf('month').format(),
        date_to: moment().endOf('day').format()
      };

      await axiosClient
        .post(`/api/client/cleanfile`, data)
        .then((res) => {
          if (res.data.code === 200) {
            this.getCleanFiles({ data: getPayload });
            CustomNotification({
              date: true,
              title: 'CLEAN File Request Submitted',
              subTitle:
                'Once this process is completed, the output CLEAN file will be available in CLEAN Files tab',
              dispatch
            });
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async addNcoaFileList(payload) {
      const { data } = payload;

      const getPayload = {
        clientId: data.clientId,
        page: 1,
        date_from: moment().startOf('month').format(),
        date_to: moment().endOf('day').format()
      };

      await axiosClient
        .post(`/api/client/ncoa`, data)
        .then((res) => {
          if (res.data.code === 200) {
            this.getNcoaFiles({ data: getPayload });
            CustomNotification({
              date: true,
              title: 'NCOA Request Submitted',
              subTitle:
                'Once this process is completed, the NCOA provider will send notification regarding progress and file delivery and we will intimate you.',
              dispatch
            });
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async getSettings(payload) {
      const { id, setSettings } = payload;
      await axiosClient
        .get(`/api/client/${id}/settings`)
        .then((res) => {
          if (res.data.code === 200) {
            let settings = res.data.data;
            let exclude_streets_data = settings['exclude_streets_data'].reduce(
              (acc, cur) => [...acc, { id: uniqid(), name: cur }],
              []
            );
            setSettings({ ...settings, exclude_streets_data });
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async addSettings(payload) {
      const { data, id } = payload;
      await axiosClient
        .put(`/api/client/${id}/settings`, data)
        .then((res) => {
          if (res.data.code === 200) {
            CustomNotification({
              title: 'Success',
              subTitle: res.data.message,
              dispatch
            });
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async getGlobalSettings(payload) {
      const { id, setSettings } = payload;
      await axiosClient
        .get(`/api/user/${id}/settings`)
        .then((res) => {
          if (res.data.code === 200) {
            let settings = res.data.data;
            setSettings({ ...settings });
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async addGlobalSettings(payload) {
      const { data, id } = payload;
      await axiosClient
        .put(`/api/user/${id}/settings`, data)
        .then((res) => {
          if (res.data.code === 200) {
            CustomNotification({
              title: 'Success',
              subTitle: res.data.message,
              dispatch
            });
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async getCleanFiles(payload) {
      const { data } = payload;
      await axiosClient
        .post(`/api/client/cleanfile/get`, data)
        .then((res) => {
          if (res.data.code === 200) {
            this.setClientData({ name: 'cleanFiles', data: res.data.data });
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async deleteCleanFile(payload) {
      const { id, getPayload } = payload;
      await axiosClient
        .delete(`/api/client/cleanfile/${id}`)
        .then((res) => {
          if (res.data.code === 200) {
            this.getCleanFiles({ data: getPayload });
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        })
        .finally(() => dispatch.notiModel.setModalData({}));
    },
    async getNcoaFiles(payload) {
      const { data } = payload;
      return await axiosClient
        .post(`/api/client/ncoa/get`, data)
        .then((res) => {
          if (res.data.code === 200) {
            this.setClientData({ name: 'ncoaFiles', data: res.data.data });
            return res.data.data
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async getAllNcoaFiles(payload) {
      const { data } = payload;
      await axiosClient
        .post(`/api/client/jobdrop/getNcoa`, data)
        .then((res) => {
          if (res.data.code === 200) {
            this.setClientData({ name: 'ncoaFiles', data: res.data.data });
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },

    async getExportData(id) {
      await axiosClient
        .get(`/api/client/${id}/export`)
        .then((res) => {
          if (res.data.code === 200) {
            this.setClientData({ name: 'exportData', data: res.data.data });
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async updateExportData(payload) {
      await axiosClient
        .post(`/api/client/export`, payload)
        .then((res) => {
          if (res.data.code === 200) {
            this.getExportData(payload.client);
            // CustomNotification({
            //   title: 'Info',
            //   subTitle: res.data.message,
            //   dispatch
            // });
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async getJobDrops(payload) {
      let response = await axiosClient
        .post(`/api/client/jobdrop/get`, payload)
        .then((res) => {
          if (res.data.code === 200) {
            let temp = res.data.data.docs;

            let updatedDocs = temp.reduce(
              (
                acc,
                {
                  alesco_out_count,
                  alesco_out_file,
                  ncoa_out_count,
                  ncoa_out_file,
                  ...rest
                }
              ) => {
                let temp = { ...rest, quoteList: [] };
                if (ncoa_out_file) {
                  temp = {
                    ...temp,
                    quoteList: [
                      ...temp.quoteList,
                      { ncoa_out_count, ncoa_out_file, name: 'Drop 1 - DMS' }
                    ]
                  };
                }

                if (alesco_out_file) {
                  temp = {
                    ...temp,
                    quoteList: [
                      ...temp.quoteList,
                      {
                        alesco_out_count,
                        alesco_out_file,
                        name: 'Drop 2 - Alesco'
                      }
                    ]
                  };
                }
                return [...acc, temp];
              },
              []
            );

            this.setClientData({
              name: 'jopDrops',
              data: { ...res.data.data, docs: updatedDocs }
            });
            return updatedDocs;
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
      return response;
    },
    async getIniJobDetails(payload) {
      await axiosClient
        .post(`/api/client/jobdrop/initialdetails`, payload)
        .then((res) => {
          if (res.data.code === 200) {
            this.setClientData({ name: 'initialdetails', data: res.data.data });
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async addJobDrop(payload) {
      const { data, handleNavigate } = payload;
      await axiosClient
        .post(`/api/client/jobdrop`, data)
        .then((res) => {
          if (res.data.code === 200) {
            const initData = {
              page: 1,
              limit: 10,
              search: '',
              date_from: moment().subtract(30, 'day').format(),
              date_to: moment().endOf('day').format()
            };

            dispatch.clientModel
              .getJobDrops({ ...initData, client: data.client })
              .then(() => {
                handleNavigate(`/client/${data.client}/jobs`);
              })
              .catch((err) => console.log(err))
              .finally(() => {
                CustomNotification({
                  date: true,
                  title: 'Job/Drop Request Submitted',
                  subTitle:
                    'Once this process is complete, the completed job output will be available in Quotes/Jobs/Drops tab',
                  dispatch
                });
              });
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async checkJobDrop(payload) {
      const { nextPayload, data, handleNavigate } = payload;
      await axiosClient
        .post(`/api/client/jobdrop/check`, data)
        .then((res) => {
          if (res.data.code === 200) {
            if (res.data.data.flag) {
              dispatch.notiModel.setModalData({
                Component: (
                  <DeleteUser
                    data={{ id: 'nothing' }}
                    handleClose={() => dispatch.notiModel.setModalData({})}
                    handleDelete={() =>
                      this.addJobDrop({ data: nextPayload, handleNavigate })
                    }
                    title={
                      'A mailer drop exists for this Quote ID and Quote Line ID'
                    }
                    subTitle={
                      'A mailer drop is already available for this combination of Quote ID and Quote Line ID. if you want to proceed, the current DMS drop will be replaced with this new data. '
                    }
                    confirmText={
                      'If not, you can change the Quote ID and/or the Quote Line ID.'
                    }
                    className='add_quote_modal'
                  />
                )
              });
            } else {
              this.addJobDrop({ data: nextPayload, handleNavigate });
            }
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async getJobByQuoteId(payload) {
      const { data, setFormData, formData } = payload;
      await axiosClient
        .post(`/api/client/jobdrop/getById`, data)
        .then((res) => {
          if (res.data.code === 200) {
            let {
              category,
              drop_options,
              drop_options_flags,
              quoteId,
              quoteLineId,
              type,
              _id
            } = res.data.data;
            setFormData({
              ...formData,
              ...drop_options,
              drop_options_flags,
              quoteId,
              quoteLineId,
              type,
              category,
              _id
            });
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async deleteJobDrop(payload) {
      const { data, getPayload } = payload;
      await axiosClient
        .post(`/api/client/jobdrop/delete`, data)
        .then((res) => {
          if (res.data.code === 200) {
            this.getJobDrops(getPayload);
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        })
        .finally(() => dispatch.notiModel.setModalData({}));
    }
  })
};

export default clientModel;
