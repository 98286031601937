import { type } from '@testing-library/user-event/dist/type';
import axiosAdmin from '../../api/axiosAdmin';
import CustomNotification from '../../components/CustomNotification';

const ExportModel = {
  state: {
    ClientNamesList: {
      clientNames: [],
      keys: []
    },

  },

  reducers: {
    setExportModelData(state, payload) {
      return { ...state, [payload.name]: { ...payload.data } };
    }
  },
  effects: (dispatch) => ({
    async GetClientNames() {
      await axiosAdmin.get(`/api/query/client-names`).then(res => {
        if (res.data.code === 200) {
          this.setExportModelData({ name: "ClientNamesList", data: res.data.data });
        } else {
          CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
        }
      }).catch(err => {
        CustomNotification({ title: "Error", subTitle: err.message, dispatch });
      })
    },

    async GetQueryRecords(payload) {
      await axiosAdmin.post(`/api/query/query-records`, payload, { responseType: 'blob' })
        .then((response) => {
          if (payload?.exportType === "csv") {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url

            const contentDisposition = response.headers['content-disposition']
            let fileName = 'query_records.csv'
            
            if (contentDisposition) {
              const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              const matches = filenameRegex.exec(contentDisposition);
              if (matches != null && matches[1]) {
                const cleanFileName = matches[1].replace(/['"]/g, '');
                fileName = cleanFileName.endsWith('.csv') ? cleanFileName : cleanFileName.replace('.xlsx', '.csv');
              }
            }

            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            link.parentNode.removeChild(link)
            window.URL.revokeObjectURL(url)
          }
          else {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              })
            )

            const link = document.createElement('a')
            link.href = url

            const contentDisposition = response.headers['content-disposition']
            let fileName = 'query_records.xlsx'
            
            if (contentDisposition) {
              const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              const matches = filenameRegex.exec(contentDisposition);
              if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, '');
              }
            }
            
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            window.URL.revokeObjectURL(url)
          }
        }).catch(err => {
          CustomNotification({ title: "Error", subTitle: err.message, dispatch });
        })
    },

  })
};

export default ExportModel;
