import React from "react";
import { Route } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import Loader from "../../Layout/Loader";
import { isManagerAuthenticated } from "../../utils";
import { ManagerRouteList } from "../data/routes";
import ManagerLayout from "../../Layout/ManagerLayout";

const managerRouter = (handleNavigate,) => {

    const PrivateRouters = ({ children }) => {
        let token = isManagerAuthenticated();
        if (!token) return <Navigate to="/" />;
        return children;
    };


    return (
        ManagerRouteList.map(({ component: Component, path, name }, key) => (
            <Route
                path={path}
                element={
                    <PrivateRouters>
                        <ManagerLayout handleNavigate={handleNavigate} title={name}>
                            <React.Suspense fallback={<Loader />}>
                                <Component handleNavigate={handleNavigate} />
                            </React.Suspense>
                        </ManagerLayout>
                    </PrivateRouters>
                }
                key={key}
            />
        ))
    )
};

export default managerRouter